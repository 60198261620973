import { GameStats } from './localStorage'

export const saveStatsToApi = (
  gameStats: GameStats,
  userId: string,
  sessionId: string
) => {
  const body = `bestStreak=${gameStats.bestStreak}&currentStreak=${
    gameStats.currentStreak
  }&gamesFailed=${gameStats.gamesFailed}&successRate=${
    gameStats.successRate
  }&totalGames=${
    gameStats.totalGames
  }&winDistribution="${gameStats.winDistribution.slice(0, 6)}"`

  fetch(
    `${process.env.REACT_APP_API_HOST}/wordle/update-stats?user_id=${userId}&session_id=${sessionId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body,
    }
  )
}

export const loadStatsFromApi = async (userId: string, sessionId: string) => {
  return fetch(
    `${process.env.REACT_APP_API_HOST}/wordle/get-stats?user_id=${userId}&session_id=${sessionId}`
  ).then((res) => res.json())
}
