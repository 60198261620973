import { getGuessStatuses } from './statuses'
import { solutionIndex } from './words'
import { GAME_TITLE } from '../constants/strings'

export const shareStatus = (guesses: string[], lost: boolean) => {
  // navigator.clipboard.writeText(
  //   `${GAME_TITLE} День #${solutionIndex} ${
  //     lost ? 'X' : guesses.length
  //   }/6\n\n` +
  //     generateEmojiGrid(guesses) +
  //     `\n\n #wordle(КД) \n\n Отгадайте слово на ${process.env.REACT_APP_SHARE_APP_LINK}`
  // )
  return (
    `${GAME_TITLE} День #${solutionIndex} ${
      lost ? 'X' : guesses.length
    }/6\n\n` +
    generateEmojiGrid(guesses) +
    `\n\n #СловаKD \n\n Отгадайте слово на ${process.env.REACT_APP_SHARE_APP_LINK}`
  )
}

export const generateEmojiGrid = (guesses: string[]) => {
  return guesses
    .map((guess) => {
      const status = getGuessStatuses(guess)
      return guess
        .split('')
        .map((letter, i) => {
          switch (status[i]) {
            case 'correct':
              return '🟩'
            case 'present':
              return '🟨'
            default:
              return '⬜'
          }
        })
        .join('')
    })
    .join('\n')
}
