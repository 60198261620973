export const WORDS = [
  'полет',
  'среда',
  'магия',
  'диван',
  'катер',
  'отдел',
  'капля',
  'товар',
  'пират',
  'плоть',
  'метод',
  'полка',
  'весна',
  'мечта',
  'пенал',
  'халат',
  'пакет',
  'архив',
  'бонус',
  'номер',
  'горох',
  'лодка',
  'вечер',
  'аршин',
  'обмен',
  'доска',
  'устав',
  'почта',
  'булка',
  'джинн',
  'птица',
  'паста',
  'мюсли',
  'уголь',
  'песок',
  'стиль',
  'ответ',
  'жизнь',
  'замок',
  'какао',
  'кошка',
  'покер',
  'арбуз',
  'роман',
  'рубин',
  'судак',
  'грудь',
  'сглаз',
  'стопа',
  'сквер',
  'сырок',
  'фрукт',
  'касса',
  'хомяк',
  'тенор',
  'умора',
  'чипсы',
  'фауна',
  'лейка',
  'диета',
  'падеж',
  'газон',
  'кешью',
  'мазут',
  'глава',
  'щиток',
  'ясень',
  'мафия',
  'кредо',
  'калий',
  'тесто',
  'пойма',
  'разум',
  'зебра',
  'лепта',
  'столб',
  'шорты',
  'пицца',
  'сосуд',
  'франт',
  'навес',
  'порча',
  'хвост',
  'этика',
  'зефир',
  'барон',
  'скейт',
  'помпа',
  'ярлык',
  'смерч',
  'фикус',
  'зачет',
  'самец',
  'треск',
  'щипцы',
  'ролик',
  'ситро',
  'вокал',
  'копье',
  'сурок',
  'такса',
  'пульс',
  'желоб',
  'домик',
  'кишка',
  'шабаш',
  'сталь',
  'вираж',
  'жниво',
  'драфт',
  'старт',
  'опция',
  'ворон',
  'рейка',
  'вишня',
  'масть',
  'ружье',
  'жилет',
  'хмель',
  'диско',
  'пекло',
  'спред',
  'кебаб',
  'бизон',
  'почет',
  'слюда',
  'окрик',
  'фазан',
  'базар',
  'вылет',
  'селфи',
  'ступа',
  'бочка',
  'торба',
  'венгр',
  'мысль',
  'телка',
  'отдых',
  'баржа',
  'штырь',
  'ревью',
  'балка',
  'цыган',
  'забой',
  'излет',
  'оклад',
  'рычаг',
  'чутье',
  'лесть',
  'хохот',
  'шурин',
  'огонь',
  'кочан',
  'сыщик',
  'штора',
  'брюхо',
  'мороз',
  'родео',
  'купаж',
  'свара',
  'скоба',
  'кусок',
  'минус',
  'тотем',
  'конус',
  'бытие',
  'посев',
  'хамон',
  'ванна',
  'гость',
  'маска',
  'режим',
  'узбек',
  'егерь',
  'маляр',
  'хвала',
  'брасс',
  'латте',
  'вобла',
  'шофер',
  'тюнер',
  'резня',
  'манеж',
  'слуга',
  'кокни',
  'херес',
  'апорт',
  'фильм',
  'фляга',
  'ислам',
  'палач',
  'телец',
  'ссора',
  'киоск',
  'аудио',
  'карат',
  'счеты',
  'папка',
  'пилот',
]
