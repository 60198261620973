import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="ПРАВИЛА ИГРЫ" isOpen={isOpen} handleClose={handleClose}>
      <p className="mb-4 text-sm text-gray-500 dark:text-gray-300">
        Каждый день в игре загадано слово из пяти букв. Ваша задача — угадать
        его с шести попыток.
      </p>
      <p className="mb-4 text-sm text-gray-500 dark:text-gray-300">
        После каждой попытки буквы будут окрашиваться в разные цвета. Вы
        увидите, какие буквы есть в загаданном слове, а каких нет.
      </p>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Буква <strong>С</strong> есть в загаданном слове, но находится в другом
        месте.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="Р" />
        <Cell value="Е" />
        <Cell value="Д" />
        <Cell value="И" />
        <Cell value="С" status="present" />
      </div>

      <hr className="my-4" />

      <p className="text-sm text-gray-500 dark:text-gray-300">
        Буквы <strong>С</strong> и <strong>О</strong> есть в загаданном слове и
        находятся на своих местах.
      </p>
      <div className="flex justify-center mt-4 mb-4">
        <Cell value="Д" />
        <Cell value="О" status="correct" />
        <Cell value="С" status="correct" />
        <Cell value="К" />
        <Cell value="А" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Если в вашем слове две одинаковые буквы, а в загаданном — одна, цветом
        выделяется только одна буква.
      </p>

      <hr className="my-4" />

      <p className="text-sm text-gray-500 dark:text-gray-300">
        Если все буквы выделены зеленым, значит, слово угадано правильно!
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="К" status="correct" />
        <Cell value="О" status="correct" />
        <Cell value="С" status="correct" />
        <Cell value="Т" status="correct" />
        <Cell value="Ь" status="correct" />
      </div>

      <hr className="my-4" />

      <p className="text-sm text-gray-500 dark:text-gray-300">
        Если буква выделяется серым, то её нет в загаданном слове.
      </p>
      <div className="flex justify-center mb-4 mt-4">
        <Cell value="Ф" status="absent" />
        <Cell value="Е" />
        <Cell value="Р" />
        <Cell value="М" />
        <Cell value="А" />
      </div>

      <p className="text-sm text-gray-500 dark:text-gray-300">
        Буквы в зашифрованном слове могут повторяться. Буква Ё во всех словах
        заменена на Е.{' '}
      </p>

      <hr className="my-4" />

      <div className="flex items-center justify-between mb-6">
        <p className="font-bold mr-4">
          Ежедневно загадывается <br /> новое слово!
        </p>
        <button
          type="button"
          className="mt-2 flex items-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-success text-base font-medium text-white focus:outline-none sm:text-sm"
          onClick={handleClose}
        >
          Играть!
        </button>
      </div>

      {/* <div className="text-center">
        <a
          href="https://www.powerlanguage.co.uk/wordle/"
          className="text-sm underline hover:no-underline text-[#909090]"
        >
          Оригинальная версия игры
        </a>
      </div> */}
    </BaseModal>
  )
}
