import { compose, replace, split, slice, map } from 'ramda'

import {
  GameStats,
  loadStatsFromLocalStorage,
  saveStatsToLocalStorage,
} from './localStorage'

import { loadStatsFromApi, saveStatsToApi } from './api'

// In stats array elements 0-5 are successes in 1-6 trys

export const addStatsForCompletedGame = (
  gameStats: GameStats,
  count: number,
  userId: string,
  sessionId: string
) => {
  // Count is number of incorrect guesses before end.
  const stats = { ...gameStats }

  stats.totalGames += 1

  if (count > 5) {
    // A fail situation
    stats.currentStreak = 0
    stats.gamesFailed += 1
  } else {
    stats.winDistribution[count] += 1
    stats.currentStreak += 1

    if (stats.bestStreak < stats.currentStreak) {
      stats.bestStreak = stats.currentStreak
    }
  }

  stats.successRate = getSuccessRate(stats)

  // saveStatsToLocalStorage(stats)
  saveStatsToApi(stats, userId!, sessionId!)
  return stats
}

export const defaultStats: GameStats = {
  winDistribution: [0, 0, 0, 0, 0, 0],
  gamesFailed: 0,
  currentStreak: 0,
  bestStreak: 0,
  totalGames: 0,
  successRate: 0,
}

const convertWinDistributionToArr = compose(
  map(Number),
  split(''),
  slice(0, 6),
  replace(/[^a-zA-Z0-9 ]/g, '')
)

export const loadStats = async (userId: string, sessionId: string) => {
  // return loadStatsFromLocalStorage() || defaultStats

  try {
    const res = await loadStatsFromApi(userId!, sessionId!)

    res.winDistribution = convertWinDistributionToArr(res.winDistribution)

    return res
  } catch (error) {
    return defaultStats
  }
}

const getSuccessRate = (gameStats: GameStats) => {
  const { totalGames, gamesFailed } = gameStats

  return Math.round(
    (100 * (totalGames - gamesFailed)) / Math.max(totalGames, 1)
  )
}
