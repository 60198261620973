export const GAME_TITLE = 'Слова KD'

export const WIN_MESSAGES = ['Great Job!', 'Awesome', 'Well done!']
export const GAME_COPIED_MESSAGE =
  'Текст результатов скопирован в буфер обмена!'
export const ABOUT_GAME_MESSAGE = 'About this game'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'В слове не хватает букв!'
export const WORD_NOT_FOUND_MESSAGE =
  'В словаре игры нет такого слова. Попробуй другое.'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `Загаданное слово: ${solution}`
export const ENTER_TEXT = 'Ввод'
export const DELETE_TEXT = 'Удалить'
export const STATISTICS_TITLE = 'Статистика'
export const GUESS_DISTRIBUTION_TEXT = 'Распределение попыток'
export const NEW_WORD_TEXT = 'Следующее слово появится через'
export const SHARE_TEXT = 'Поделиться'
export const TOTAL_TRIES_TEXT = 'Сыграно'
export const SUCCESS_RATE_TEXT = 'Побед всего'
export const CURRENT_STREAK_TEXT = 'Побед подряд сейчас'
export const BEST_STREAK_TEXT = 'Подряд максимум'
