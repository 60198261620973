import { CharStatus } from '../../lib/statuses'
import classnames from 'classnames'

type Props = {
  value?: string
  status?: CharStatus
}

const PresentIcon = () => (
  <svg
    className="absolute top-1 right-1"
    width="9"
    height="9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="4.5" cy="4.5" r="3.5" stroke="#fff" strokeWidth="1.8" />
  </svg>
)

const CorrectIcon = () => (
  <svg
    className="absolute top-1 right-1"
    width="9"
    height="8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m1 4 2.5 3 4-6"
      stroke="#fff"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const Cell = ({ value, status }: Props) => {
  const classes = classnames(
    'relative w-14 h-14 border-solid border-2 flex items-center justify-center mx-0.5 text-lg font-bold rounded dark:text-white',
    {
      'bg-white dark:bg-slate-900 border-slate-200 dark:border-slate-600':
        !status,
      'border-black dark:border-slate-100': value && !status,
      'bg-slate-400 dark:bg-slate-700 text-white border-slate-400 dark:border-slate-700':
        status === 'absent',
      'bg-success text-white border-success': status === 'correct',
      'bg-warning dark:bg-warning text-white border-warning dark:border-warning':
        status === 'present',
      'cell-animation': !!value,
    }
  )

  return (
    <div className={classes}>
      {status === 'present' && <PresentIcon />}
      {status === 'correct' && <CorrectIcon />}
      {value}
    </div>
  )
}
